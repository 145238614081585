import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <h1>Page not found</h1>
    <p>
      Oops, you found a bad link or perhaps typed in the wrong URL.{" "}
      <a href="/">Go back home</a>
    </p>
  </Layout>
)

export default NotFoundPage
